@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');


@tailwind base;
@tailwind components;
@tailwind utilities;


.dm-sans {
  font-family: "DM Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}
.linear {
  background: rgb(31,80,89);
background: linear-gradient(180deg, rgba(31,80,89,1) 0%, rgba(147,194,180,1) 49%);
}
.bg-gradient{
  background: rgb(221,239,227);
  background: linear-gradient(185deg, rgba(221,239,227,1) 0%, rgba(81,161,134,1) 95%);
}
@keyframes slideIn {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slideOut {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}

.animate-slide-in {
  animation: slideIn 0.5s forwards;
}

.animate-slide-out {
  animation: slideOut 0.5s forwards;
}
